import React, { useEffect } from 'react';
import {
  Typography,
  Container,
} from '@mui/material';
import { colors } from '../colors';
import ReactGA from 'react-ga';

const Checkout: React.FC = () => {
  useEffect(()=>{
    ReactGA.initialize('G-72C9EDHHM0');
    ReactGA.set({ page: 'checkout' });
    ReactGA.pageview('checkout');
    document.title = "checkout";
  }, [])
  return (
    <Container >
      <Typography variant="h2" align="center" marginTop={5} color={colors.primary}>
        Medik agradece seu contato
      </Typography>
      <Typography variant="h5" marginTop={5} align="center">
        Nos aguarde e retornaremos o mais breve possível!
      </Typography>
    </Container>
  )
}

export default Checkout;